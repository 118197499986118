<template>
  <div id="Artists">

    <div class="trending-artists">
      <div class="top">
        <div class="title">{{ $t("artist.trendingArtists") }}</div>
        <b-button @click="onClickApply">{{ $t("artist.button.apply") }}</b-button>
      </div>

      <div
        class="trending_artists_slide artists-wrap"
        v-if="trendingArtistItems !== undefined &&
              trendingArtistItems !== null &&
              trendingArtistItems.length > 0"
      >
        <slick :options="_slickOptions">
          <template v-for="(item, i) in trendingArtistItems">
            <div class="artist-card" @click.stop="onClickArtist(item.idxMember)" :key="i">
              <div class="artist-card-img-container" @contextmenu.prevent.stop>
                <div class="dummy"/>
                <div class="artist-card-img"><b-img :src="item.artworkImageUrl"></b-img></div>
              </div>
              <div class="artist-card-profile">
                <template v-if="item.profileImage === undefined">
                  <CImg
                    class="c-avatar-img"
                    src="/img/avatars/profile-gray-person.jpg"
                  />
                </template>
                <template v-else>
                  <CImg
                    class="c-avatar-img"
                    :src="item.profileImageUrl"
                  />
                </template>
                <div class="artist_name">
                  <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                    {{ item.artistName || item.artistNameEn }}
                  </template>
                  <template v-else>
                    {{ item.artistNameEn || item.artistName }}
                  </template>
                </div>
              </div>
              <div class="artist-card-stat">
                <div class="item">
                  <div class="type">DROPS</div>
                  <div class="value">{{ item.artworks }}</div>
                </div>
                <div class="item">
                  <div class="type">TOTAL VIEWS</div>
                  <div class="value">{{ item.viewers | currency }}</div>
                </div>
                <div class="item">
                  <div class="type">TOTAL VOLUME</div>
                  <div class="value">{{ item.volume | currency }}{{ item.volumeUnit }}</div>
                </div>
              </div>
            </div>
          </template>
        </slick>
      </div>
    </div>

    <div class="search-section">
      <b-form-input
        class="search-box"
        type="text"
        placeholder="Search..."
        autocomplete="off"
        v-model="externalParams.searchKeyword"
        @keypress.enter.prevent="onClickSearch"
      />
      <div @click="onClickSearch" class="search-btn">
        <img src="/img/threespace/icon/search_icon.png" />
      </div>
      <div class="reset-btn"
        @click="onClickResetFilterKeyword"
        v-if="externalParams.searchKeyword !== ''"
      >
        <img src="/img/threespace/icon/search_reset_icon.png" />
      </div>
      <b-button
        class="sort-btn"
        :class="{ active: visibleSortBy }"
        @click.stop="showSortByFilter"
      >
        {{ Object.keys(sortSelected).length > 0 ? sortSelected.text : $t("product.sortBy") }}
        <v-icon class="float-right" v-if="visibleSortBy">mdi-chevron-up</v-icon>
        <v-icon class="float-right" v-else>mdi-chevron-down</v-icon>
      </b-button>
      <div
        class="sort-list"
        :class="{ open: visibleSortBy }"
        v-click-outside:showSortByFilter="false"
      >
        <template v-for="(item, i) in sortOptions">
          <div
            :key="i"
            class="sort-item"
            :class="{ active: sortSelected.text === item.text }"
            @click="onClickSortByItem(item)"
          >
            {{ item.text }}
          </div>
        </template>
      </div>
    </div>

    <div>
      <transition-group name="artists" tag="div" class="artists-section">
      <div
        class="artist-card"
        v-for="(item, i) in artistItemsContent"
        @click.stop="onClickArtist(item.idxMember)"
        :key="'artistItem' + i"
      >
        <div class="artist-card-img-container" @contextmenu.prevent.stop>
          <div class="dummy"/>
          <div class="artist-card-img"><b-img :src="item.artworkImageUrl"></b-img></div>
        </div>
        <div class="artist-card-skills">
          <div v-for="(skill, i) in item.skills" class="skill" :key="'skils'+i">
            {{replaceSkilltoText(skill)}}
          </div>
        </div>
        <div class="artist-card-profile">
          <template v-if="item.profileImage === undefined">
            <CImg
              class="c-avatar-img"
              src="/img/avatars/profile-gray-person.jpg"
            />
          </template>
          <template v-else>
            <CImg
              class="c-avatar-img"
              :src="item.profileImageUrl"
            />
          </template>
          <div class="artist_name">
            <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
              {{ item.artistName || item.artistNameEn }}
            </template>
            <template v-else>
              {{ item.artistNameEn || item.artistName }}
            </template>
          </div>
        </div>
        <div class="artist-card-stat">
          <div class="item" @click.stop="onClickFollowers(item.idxMember)">
            <div class="type">FOLLOWERS</div>
            <div class="value">{{ item.followers | currency }}</div>
          </div>
          <div class="item" @click.stop="onClickFollowing(item.idxMember)">
            <div class="type">FOLLOWING</div>
            <div class="value">{{ item.following | currency }}</div>
          </div>
        </div>
        <div class="artist-follow-btn-container">
          <button :id="'follow-'+i" class="follow-btn" @click.stop="onClickArtistFollow(item)" :class="{ 'followed': item.isFollow }">
            {{ item.isFollow !== undefined && item.isFollow ? 'UNFOLLOW' : 'FOLLOW' }}
          </button>
        </div>
      </div>
      </transition-group>
      <scroll-loader
        :loader-method="requestArtistItems"
        :loader-disable="isLast"
      >
        <svg
          data-v-c2de8a26=""
          viewBox="25 25 50 50"
          class="loader__svg"
          style="width: 50px;"
        >
          <circle
            data-v-c2de8a26=""
            cx="50"
            cy="50"
            r="20"
            class="loader__circle"
            style="stroke: rgb(204, 204, 204);"
          ></circle>
        </svg>
      </scroll-loader>
    </div>

    <FollowModal ref="followModal" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const artistHelper = createNamespacedHelpers("artist");
const codeHelper = createNamespacedHelpers("code");
const authHelper = createNamespacedHelpers("auth");

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

import FollowModal from "@/views/threespace/components/follow/FollowModal";

export default {
  name: "Artists",
  components: {
    Slick,
    FollowModal
  },
  // metaInfo() {
  //   return {
  //     title: this.$t("meta.artist.title") + " | 3space Art",
  //     meta: [
  //       { vmid: "title", name: "title", content: this.$t("meta.artist.title") + " | 3space Art" },
  //       { vmid: "description", name: "description", content: this.$t("meta.artist.description") },
  //       { vmid: "og:title", property: "og:title", content: this.$t("meta.artist.title") + " | 3space Art" },
  //       { vmid: "og:description", property: "og:description", content: this.$t("meta.artist.description") },
  //       { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
  //     ]
  //   }
  // },
  data() {
    return {
      slickOptions: {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              arrows: false,
              dots: false,
              infinite: true,
              centerMode: true,
              slidesToShow: 1,
              autoplay: false,
              speed: 500,
            }
          }
        ]
      },
      mobileSlickOptions: {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
      },
      isBusy: false,
      perPage: 8,
      isLast: true,
      currentPage: 0,
      sort: this.getInitSort(),
      sortSelected: {},
      sortOptions: [
        {
          value: { id: "artistStat.nftMintDate", desc: "desc" },
          text: this.$t("artist.sortByNewest"),
        }
      ],
      externalParams: {
        searchKeyword: ""
      },
      visibleSortBy: false
    };
  },
  computed: {
    ...artistHelper.mapState(["trendingArtistItems", "artistItems", "artistItemsContent"]),
    ...codeHelper.mapState(["commonCodes"]),
    ...authHelper.mapState(["me"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    _slickOptions() {
      if (this.isMobile) {
        return this.mobileSlickOptions
      } else {
        return this.slickOptions
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.requestTrendingArtistItems();
      this.requestChildCodes(["ARTIST_SKILL"]);
    });
  },
  directives: {
    "click-outside": {
      bind: function(el, binding, vnode) {
        // create event
        el.eventClickOutside = function(event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.arg](binding.value);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function(el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      }
    }
  },
  methods: {
    ...artistHelper.mapActions(["getTrendingArtists", "getArtists", "updateArtistFollow"]),
    ...codeHelper.mapActions(["getChildMultiCodes"]),
    ...authHelper.mapActions(["verifyTokenCallback"]),
    requestChildCodes(codes) {
      this.getChildMultiCodes(codes).then(() => {
        this.requestArtistItems();
      });
    },
    requestTrendingArtistItems() {
      this.isBusy = true;
      this.getTrendingArtists()
        .then(() => {
          this.isBusy = false;
        }).catch(error => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    requestArtistItems() {
      this.isBusy = true;
      this.getArtists(
        this.getPagingParams(
          {
            page: this.currentPage,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
      .then(data => {
        this.isLast = data.last;
        this.currentPage = data.pageable.page + 1;
        this.isBusy = false;
      })
      .catch(error => {
        this.isBusy = false;
        this.$log.error(error);
      });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    },
    onClickSearch() {
      this.isLast = true;
      this.currentPage = 0;
      this.requestArtistItems();
    },
    onClickResetFilter() {
      this.externalParams.searchKeyword = "";
      this.sort = this.getInitSort();
      this.sortSelected = {};
      this.onClickSearch();
    },
    onClickResetFilterKeyword() {
      this.externalParams.searchKeyword = "";
      this.onClickSearch();
    },
    onClickSortByItem(item) {
      this.sort[0] = item.value;
      this.visibleSortBy = false;
      this.isLast = true;
      this.currentPage = 0;
      this.sortSelected = item;
      this.requestArtistItems();
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.visibleSortBy = value;
      else {
        this.visibleSortBy = !this.visibleSortBy;
      }
    },
    onClickArtist(idxMember) {
      // this.$router.push("/artist/" + idxArtist);
      let idxEncode = window.btoa(idxMember);
      this.$router.push("/collections/" + idxEncode);
    },
    onClickApply() {
      let routeUrl = this.$router.resolve({ path: "/apply" });
      window.open(routeUrl.href, "_blank");
    },
    onClickArtistFollow(item) {
      this.verifyTokenCallback()
        .then(() => {
          if (
            this.me === undefined ||
            this.me === null ||
            this.me.idx === undefined ||
            this.me.idx === null ||
            this.me.idx === ""
          ) {
            // 로그인
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("artist.requiredLogin"),
              show: true,
              fontSize: "sm"
            });
          } else {
            // Follow/Unfollow
            const follow = item.isFollow !== undefined && item.isFollow === true ? false : true;
            const param = { idx: item.idx, follow: follow, referral: null };  // TODO
            this.updateArtistFollow(param)
              .then(data => {
                if (Object.keys(data).length !== 0 && JSON.stringify(data) !== JSON.stringify({})) {
                  item.isFollow = data.isFollow;
                  item.followers = data.followers;
                  item.following = data.following;
                }
              })
              .catch(error => {
                this.$log.error(error);
                alert(this.$t("artist.failTryAgain"));
              });
          }
        })
        .catch(error => {
          this.$log.error(error);
          // 로그인
          this.$store.commit("setCommonAlertModalInfo", {
            msgHtml: this.$t("artist.requiredLogin"),
            show: true,
            fontSize: "sm"
          });
        });
    },
    onClickFollowers(_idxMember) {
      this.$refs["followModal"].show(0, _idxMember);
    },
    onClickFollowing(_idxMember) {
      this.$refs["followModal"].show(1, _idxMember);
    },
    replaceArtistSkillKo(skills) {
      if (skills !== undefined && skills !== null && skills.length > 0) {
        let skillTexts = new Array();
        skills.forEach(item => {
          try {
            skillTexts.push(this.commonCodes["ARTIST_SKILL"].find(o => o.value === item.codeValue).text);
          } catch (error) {
            this.$log.error(error);
          }
        });
        return skillTexts.join(", ");
      }
      return "";
    },
    replaceSkilltoText(skill) {
      return this.commonCodes["ARTIST_SKILL"].find(o => o.value === skill.codeValue).text;
    },
    getInitSort() {
      return [
        { id: "updatedAt", desc: "desc" },
        { id: "idx", desc: "desc" }
      ];
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    }
  },
  filters: {
    currency: value => {
      if (!value) return 0;
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
};
</script>
